import React, { Component } from 'react';
import "./Footer.scss";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SocialMedia from 'components/socialMedia/SocialMedia';

export default class FrontFooter extends Component {
    render() {
        return (
            <div id="footer">
                <Container>
                    <Row>
                        <Col>
                            <a id="email" href="mailto:lobbydata@gmail.com">lobbydata@gmail.com</a>
                            <SocialMedia />
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}
